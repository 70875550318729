<template>
  <div :class="$style.container">
    <div
      :class="$style.scroll"
      v-scroll="{ global: !wrap, onBottomFetch: loadMore }"
      v-if="initialLoad.status.value === 'success'"
    >
      <app-leaders-list-item-top
        v-if="first"
        :source="first"
        :class="$style.achieve"
      />
      <app-leaders-list-item
        v-for="item in other"
        :key="item.member.id"
        :source="item"
        :class="$style.achieve"
      />
      <ui-spinner
        v-if="loadMore.status.value === 'pending'"
        :class="$style.spinner"
      />
    </div>

    <ui-spinner v-if="initialLoad.pending.value" :class="$style.spinner" />

    <div
      :class="[$style.self, !wrap && $style.fixed]"
      v-if="myRank && showMyPosition"
    >
      <app-leaders-list-item :source="myData" is-you />
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted } from 'vue'
import AppLeadersListItem from '~/components/leaders/listItem'
import AppLeadersListItemTop from '~/components/leaders/listItemTop'
import UiSpinner from '~/components/ui/spinner'
import { useLeaderboardStore } from '~/stores/common/leaderboard'
import { useMainStore } from '@/stores/common/main.js'
import { useAccountStore } from '@/stores/account'

defineProps({
  wrap: {
    type: Boolean,
    default: false
  }
})

const { $auth } = useNuxtApp()
const leaderboardStore = useLeaderboardStore()
const mainStore = useMainStore()
const accountStore = useAccountStore()

const items = computed(() => leaderboardStore.items)
const myRank = computed(() => accountStore.ranks?.[0])
const showMyPosition = computed(
  () => myRank.value && myRank.value.position > 10
)
const first = computed(
  () => leaderboardStore.leaders.length && leaderboardStore.leaders[0]
)
const other = computed(
  () => leaderboardStore.leaders.length && leaderboardStore.leaders.slice(1)
)
const myData = computed(() => ({
  position: myRank.value.position,
  position_before: myRank.value.position_before,
  member: $auth.account,
  coins: $auth.account.coins
}))

const loadMore = useAsyncData(() => leaderboardStore.fetchMoreItems(), {
  immediate: false,
  server: false
})
const initialLoad = useAsyncData(
  () => leaderboardStore.fetchItemsWithAchievements(),
  { immediate: false, server: false }
)

onMounted(async () => {
  await initialLoad.execute()
})
</script>

<style lang="scss" module>
.container {
  position: relative;
  padding: 0 2em;
  height: 100%;
  overflow: hidden;
  background: var(--t-app-l2-bg);
  @include down(sm) {
    padding: 0 1.6em;
    background: transparent;
  }
}
.achieve {
  margin-bottom: 1em;
  &:last-child {
    margin-bottom: 11em;
  }
}
.scroll {
  @include hide-scrollbar;
  scroll-behavior: smooth;
  overflow: auto;
  height: 100%;
  padding-bottom: 10em;
}
.self {
  position: absolute;
  padding-bottom: 1.2em;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(#252c3e, 0.6);
  backdrop-filter: blur(5px);
  z-index: 4;
  &.fixed {
    position: fixed;
  }
  &:before {
    content: '';
    display: block;
    border-top: 1px solid #41435c;
    margin: 0 2em 1.2em;
  }
}
.spinner {
  padding: 1em 0 2em;
}
</style>
