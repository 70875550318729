import type { Ranks, Achievements } from 'lvl-io-sdk'
import { createItemsStore } from '~/utils/pinia/light-items-store'

interface Rank extends Ranks.RankExpanded {
  achievements?: Achievements.Achievement[]
}

export const useLeaderboardStore = createItemsStore(
  'leaderboard',
  {
    getItemsRequests: async () => {
      const { $sdk } = useNuxtApp()
      const {
        ranks: { idKey, getItems, getMoreItems }
      } = await $sdk.module('ranks')

      return {
        idKey,

        getItems: (
          ...[requestQuery, pathOptions, requestOptions]: Parameters<
            typeof getItems<Rank>
          >
        ) =>
          getItems<Rank>(
            {
              expand: ['coins', 'member.styles', 'member.clan'],
              fields: [
                'coin_code',
                'balance',
                'position',
                'position_before',
                'member.avatar',
                'member.id',
                'member.username',
                'member.styles'
              ],
              ...(requestQuery ?? {})
            },
            pathOptions,
            requestOptions
          ),

        getMoreItems: getMoreItems<Rank>
      }
    }
  },
  context => {
    const { $sdk } = useNuxtApp()

    const fetchItemsWithAchievements = async (
      ...args: Parameters<(typeof context)['fetchItems']>
    ) => {
      const items = await context.fetchItems(...args)
      const firstItem = items[0] as Rank | undefined

      if (!firstItem) return items

      const achievements = await $sdk
        .module('achievements')
        .then(({ memberAchievements }) =>
          memberAchievements.getItems(
            { limit: 5, 'filter[progress][=]': 100 },
            { id: firstItem.member.id },
            { pagination: false }
          )
        )

      context.items.value[0].achievements = achievements.items

      return items
    }

    const leaders = computed(() =>
      context.items.value.map(item => ({
        position: item.position,
        position_before: item.position_before,
        member: Object.assign(
          {
            achievements: item.achievements
          },
          item.member
        ),
        coins: [
          {
            code: item.coin_code,
            balance: item.balance
          },
          ...item.coins
        ]
      }))
    )

    return { fetchItemsWithAchievements, leaders }
  }
)
