<template>
  <div :class="[$style.container, top && $style.top, isYou && $style.self]">
    <div>
      <div v-if="isYou" :class="$style.you">{{ t('You') }}:</div>
      <div :class="$style.rank">#{{ source.position }}</div>
    </div>
    <ui-link :to="`/profile/${source.member.id}`">
      <ui-avatar
        :class="$style.avatar"
        :src="source.member.avatar"
        :border="source.member.styles.avatarBorderColor"
        :sticker="source.member.styles.sticker"
        size="large"
      />
    </ui-link>
    <div :class="$style.info">
      <ui-link
        :to="`/clans/${source.member.clan.tag}`"
        :class="$style.clan"
        v-if="source.member.clan"
        >[{{ source.member.clan.tag }}]</ui-link
      >
      <div :class="$style.name">
        <ui-username
          :class="$style.text"
          :color="source.member.styles.usernameColor"
          >{{ source.member.username }}</ui-username
        >
      </div>
    </div>
    <div :class="$style.coins">
      <ui-coin
        v-for="coin in source.coins"
        :class="$style.coin"
        :key="coin.code"
        :code="coin.code"
        :value="coin.balance"
        size="custom"
        is-minor
      />
    </div>
  </div>
</template>

<i18n>
{
  "ru": {
    "You": "Вы"
  }
}
</i18n>

<script>
export default {
  name: 'AppLeadersListItem'
}
</script>

<script setup>
import UiLink from '@/components/ui/link/index.vue'
import UiCoin from '@/components/ui/coin/index.vue'
import UiAvatar from '@/components/ui/avatar/index.vue'
import UiUsername from '@/components/ui/username/index.vue'

const { t } = useI18n()

const $props = defineProps({
  source: {
    type: Object,
    required: true
  },
  isYou: {
    type: Boolean,
    default: false
  }
})

const top = computed(() => $props.source.position < 4)

const balances = computed(() => {
  const result = Object.assign([], $props.source.balances)
  result.sort(item => (item.coin_code === $props.source.coin_code ? -1 : 1))
  return result
})
</script>

<style module lang="scss">
.container {
  display: flex;
  align-items: center;
  padding: 1em 2em 1em 1em;
  animation-timeline: view(block 70% -10%);
  animation-name: show;
  animation-duration: 1ms;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
}
@keyframes show {
  from {
    opacity: 0;
    transform: scale(0.8);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
.top {
  background: var(--t-app-div-color);
  border-radius: 0.2em;

  .rank {
    color: #b40091;
  }
}
.self {
  padding: 1em 2em;
  background: transparent;
  .rank {
    width: auto;
    margin-right: 1em;
  }
}
.you {
  font-weight: 500;
  font-size: 1em;
  line-height: 100%;
  color: #acb0d5;
}
.rank {
  font-family: var(--primary-font);
  font-style: var(--primary-font-style);
  font-weight: 700;
  font-size: 1.6em;
  line-height: 1.375;
  letter-spacing: -0.02em;
  color: #acb0d5;
  width: 3.3em;
  text-align: center;
}
.avatar {
  margin-right: 1em;
}
.info {
  display: flex;
  flex-direction: column;
  .clan {
    font-weight: 500;
    font-size: 1.2em;
    line-height: 127%;
    letter-spacing: -0.02em;
    color: #26ff87;
  }
  .name {
    width: 9em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    .text {
      font-weight: 500;
      font-size: 1.6em;
      line-height: 150%;
      letter-spacing: -0.02em;
      color: #ffffff;
    }
  }
}
.coins {
  margin-left: auto;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-end;
  font-size: 1.6em;

  & > :first-child {
    margin-bottom: 0.4em;
  }
}
.coin {
  white-space: nowrap;
}
</style>
