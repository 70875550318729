<template>
  <div :class="$style.container">
    <div :class="$style.rank">
      <div :class="$style.text">#01</div>
    </div>
    <div :class="$style.name">
      <ui-username
        :class="$style.text"
        :color="source.member.styles.usernameColor"
        >{{ source.member.username }}</ui-username
      >
    </div>
    <div :class="$style.clan">
      <ui-link
        :to="`/clans/${source.member.clan.tag}`"
        :class="$style.text"
        v-if="source.member.clan"
        >[{{ source.member.clan.tag }}]</ui-link
      >
    </div>
    <div :class="$style.coins">
      <ui-coin
        v-for="coin in source.coins"
        :key="coin.code"
        :code="coin.code"
        :value="coin.balance"
        size="custom"
        is-minor
      />
    </div>
    <div :class="$style.info">
      <ui-link :to="`/profile/${source.member.id}`">
        <ui-avatar
          size="custom"
          :class="$style.avatar"
          :src="source.member.avatar"
          :border="source.member.styles.avatarBorderColor"
          :sticker="source.member.styles.sticker"
        />
      </ui-link>

      <div
        :class="$style.achievements"
        v-if="source.member.achievements && source.member.achievements.length"
      >
        <div :class="$style.text">{{ t('Achievements') }}</div>
        <div :class="$style.list">
          <common-achievement
            v-for="item in source.member.achievements"
            :key="item.id"
            :class="$style.item"
            :achieve="item"
            size="custom"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<i18n>
{
  "ru": {
    "Achievements": "Ачивки"
  }
}
</i18n>

<script>
export default {
  name: 'AppLeadersListItemTop'
}
</script>

<script setup>
import UiAvatar from '~/components/ui/avatar'
import UiCoin from '~/components/ui/coin'
import CommonAchievement from '~/components/achievements/preview'
import UiLink from '~/components/ui/link'
import UiUsername from '~/components/ui/username'

const { t } = useI18n()

const $props = defineProps({
  source: {
    type: Object,
    required: true
  }
})

const balances = computed(() =>
  Object.assign([], $props.source.balances).sort(item =>
    item.coin_code === $props.source.coin_code ? -1 : 1
  )
)
</script>

<style module lang="scss">
.container {
  position: relative;
  padding: 1.6em 2em 2.1em 2em;
  border-radius: 0.6em;
  font-family: var(--primary-font), sans-serif;
  font-style: var(--primary-font-style);
  background:
    no-repeat center bottom / contain url(@/assets/images/pari-bottom-bg.png),
    #3c186d;
}
.rank {
  position: absolute;
  top: 3.8em;
  right: 1.6em;
  .text {
    font-weight: 700;
    font-size: 4.8em;
    line-height: 1.22%;
    letter-spacing: -0.02em;
    color: var(--app-active-color);
  }
}
.name {
  width: 18em;
  .text {
    font-weight: 700;
    font-size: 2.4em;
    letter-spacing: -0.02em;
    color: #edeeff;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.clan {
  margin-top: 0.35em;
  margin-bottom: 1.5em;
  .text {
    font-weight: 500;
    font-size: 1.2em;
    line-height: 127%;
    letter-spacing: -0.02em;
    color: #26ff87;
  }
}
.coins {
  display: flex;
  align-items: center;
  margin-bottom: 0.5em;
  font-size: 1.6rem;
  > :first-child {
    margin-right: 2.4em;
  }
}
.info {
  display: flex;
}
.avatar {
  width: 6.8em;
  height: 6.8em;
}
.achievements {
  margin-left: 2em;
  align-self: flex-end;
  .text {
    font-weight: 500;
    font-size: 1em;
    line-height: 100%;
    color: var(--app-color-text);
  }
  .list {
    display: flex;
    margin-top: 1.1em;
    .item {
      width: 2.8em;
      height: 2.8em;
      margin-right: 0.7em;
    }
  }
}
</style>
